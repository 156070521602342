<template>
	<div class="talk-skill-custom-classify-page">
		<van-search
			v-model="value"
			show-action
			:placeholder="$t('选择或录入新增分类')"
			maxlength="5"
			@search="onSearch"
		>
			<template #action>
				<div @click="onSearch">{{ $t('保存') }}</div>
			</template>
		</van-search>
		<div class="tag-box">
			<van-tag
				v-for="item in list"
				:key="item"
				class="tag"
				size="large"
				round
				:plain="!selection.includes(item)"
				type="primary"
				@click="onClick(item)"
			>
				{{ item }}
			</van-tag>
		</div>
		<div
			style="padding-left: 16px;font-size: 12px;color: rgba(13, 23, 26, 0.45);"
		>
			<p><van-icon name="bulb-o" />{{ $t('提示') }}：</p>
			<p>{{ $t('最多支持添加8类个人分类，超出后系统将按创建时间进行更新') }}</p>
		</div>
		<div class="actions">
			<van-button @click.prevent="$router.back()">
				{{ $t('取消') }}
			</van-button>
			<van-button type="info" color="#EED484" @click="onSave">
				{{ $t('保存') }}
			</van-button>
		</div>
	</div>
</template>
<script>
import { getPersonalTypeList, personalTypeSave } from '@/services/talk-skill.js'
import eventBus from './event-bus.js'
import { i18n } from '@/main'
import loading from '@/utils/loading'
export default {
  data() {
    return {
      value: '',
      list: [],
      selection: [],
    }
  },
  async created() {
    const list = await getPersonalTypeList()
    const { str = '' } = this.$route.query
    const arr = str.split(',')
    this.list = list.map(({ name }) => {
      arr.includes(name) && this.selection.push(name)
      return name
    })
  },
  methods: {
    async onSave() {
      loading.showLoading()
      const list = await getPersonalTypeList()
      const data = list.filter(({ name }) => this.selection.includes(name))
      loading.hideLoading()
      eventBus.$emit('changeCustomClassify', data)
      this.$router.back()
    },
    onClick(str) {
      if (this.selection.includes(str)) {
        const index = this.selection.findIndex((item) => item == str)
        this.selection.splice(index, 1)
      } else {
        this.selection.push(str)
      }
    },
    async onSearch() {
      if (this.value.length < 2) {
        this.$toast(this.$t('分类名称限制2-5字符'))
        return
      }
      if (this.list.includes(this.value)) {
        const lang = i18n.locale
        const msg = lang.includes('zh')
          ? `已设置【${this.value}】分类，请直接选择`
          : `The [${this.value}}] category has been set, please select it directly`
        this.$toast(msg)
        return
      }
      loading.showLoading()
      await personalTypeSave({ name: this.value })
      loading.hideLoading()
      const index = this.list.push(this.value)
      this.selection.push(this.value)
      if (index > 8) {
        const str = this.list.shift() // 删除第一个
        if (this.selection.includes(str)) {
          const index = this.selection.findIndex((item) => item == str)
          this.selection.splice(index, 1)
        }
      }
      this.value = ''
    },
  },
}
</script>
<style lang="less" scoped>
.actions {
	background: white;
	position: sticky;
	bottom: 0;
	padding: 16px 8px;
	display: flex;
	justify-content: space-around;
	button {
		color: #0d171a !important;
		border-radius: 4px;
		flex: 1;
		margin: 0 4px;
	}
}
.tag-box {
	overflow: hidden;
	margin: 16px 6px 6px 16px;
	.tag {
		margin-right: 10px;
		margin-bottom: 10px;
		&:last-child {
			margin-right: 0;
		}
	}
}
</style>
